import { useContext, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { Col, Descriptions, Row, Tree, Typography } from 'antd';
import {
  CheckCircleFilled,
  CloseCircleFilled,
} from '@ant-design/icons';
import { DownOutlined } from '@ant-design/icons';
import ReactJson from 'react-json-view';
import * as dayjs from 'dayjs';

import NavbarContext from '../../context/NavbarContext';
import { hashStr } from '../../utils';

import {
  getTraceAsync,
  selectLoaded,
  selectTraces,
} from './tracesSlice';

const TIME_FORMAT = 'YYYY-MM-DDTHH-mm-ss';

export function TraceView() {

  const [selectedKeys, setSelectedKeys] = useState(['call-function']);

  const traces = useSelector(selectTraces);
  const loaded = useSelector(selectLoaded);

  const { setNavbarState } = useContext(NavbarContext);

  const dispatch = useDispatch();
  const location = useLocation();

  const id = location.pathname.match(/\/traces\/(.*)/)[1];
  const trace = traces[id];

  const step = useMemo(() => {
    const inner = (trace) => {
      for (const step of trace) {
        if (step.type === selectedKeys[0]) {
          const node = { ...step };
          delete node.children;
          return node;
        }
        if (step.children) {
          const match = inner(step.children);
          if (match) {
            return match;
          }
        }
      }
      return null;
    }

    if (trace && selectedKeys.length) {
      return inner(trace.trace);
    }
    return null;

  }, [selectedKeys, trace]);

  const getTitle = (step) => {
    if (step.type === 'call-function') {
      return step.type + ' - ' + step.function;
    }
    return step.type;
  };

  const treeData = useMemo(() => {
    if (trace) {
      const inner = (t) => {
        return t.map((step) => {
          if (step.children) {
            return {
              title: getTitle(step),
              key: step.type,
              children: inner(step.children),
            };
          }
          return {
            title: step.type,
            key: step.type,
          };
        });
      }

      return inner(trace.trace);
    }
  }, [trace]);

  useEffect(() => {
    dispatch(getTraceAsync(id));
  }, []);

  useEffect(() => {
    if (trace) {
      const name = trace.name;
      const parts = name.split(' - ');
      const title = parts[0] + ' - ' + dayjs(parts[1]).format(TIME_FORMAT);
      setNavbarState((state) => ({
        ...state,
        createLink: null,
        title,
      }));
    }
  }, [trace]);

  const onSelect = (selectedKeys, info) => {
    // console.log('selected', selectedKeys, info);
    setSelectedKeys(selectedKeys);
  };

  function Step({ step }) {
    if (!step) {
      return (
        <div></div>
      );
    }
    if (step.type === 'call-function' || step.type === 'call-implementation') {
      const title = step.type === 'call-function' ? 'Call Function' : 'Call Implementation';
      return (
        <Descriptions className="trace-step" title={title} column={{ md: 1, lg: 3 }} layout="vertical">
          <Descriptions.Item span={3} label="input">
            <ReactJson collapsed src={step.args} />
          </Descriptions.Item>
          <Descriptions.Item span={3} label="output">
            {step.response.choices ?
              <div>
                {step.response.choices.map((choice, i) => (
                  <div key={hashStr(choice.message.content)}>
                    <Typography.Paragraph className={i === 0 ? 'first' : ''}>
                      {choice.message.content}
                    </Typography.Paragraph>
                    <Typography.Text type="secondary">
                      finish reason: {choice.finish_reason}
                    </Typography.Text>
                  </div>
                ))}
              </div>
              :
              <ReactJson collapsed src={step.response} />
            }
          </Descriptions.Item>
          <Descriptions.Item label="model" span={1}>
            {step.implementation.model}
          </Descriptions.Item>
          <Descriptions.Item label="params" span={2}>
            <ReactJson collapsed src={step.implementation.modelParams} />
          </Descriptions.Item>
          <Descriptions.Item label="batch" span={1}>
            {step.isBatch ? 'Yes' : 'No'}
          </Descriptions.Item>
        </Descriptions>
      );
    } else if (step.type === 'validate-function-args' || step.type === 'validate-prompt-args') {
      return (
        <Descriptions className="trace-step" title="Validate Args" column={{ md: 1, lg: 3 }} layout="vertical">
          <Descriptions.Item span={3} label="input">
            <ReactJson collapsed src={step.instance} />
          </Descriptions.Item>
          <Descriptions.Item span={3} label="schema">
            <ReactJson collapsed src={step.schema} />
          </Descriptions.Item>
          <Descriptions.Item label="valid" span={1}>
            {step.valid ? 'Yes' : 'No'}
          </Descriptions.Item>
        </Descriptions>
      );
    } else if (step.type === 'map-args') {
      return (
        <Descriptions className="trace-step" title="Map Args" column={{ md: 1, lg: 3 }} layout="vertical">
          <Descriptions.Item span={3} label="input">
            <ReactJson collapsed src={step.input.values} />
          </Descriptions.Item>
          <Descriptions.Item span={3} label="output">
            <ReactJson collapsed src={step.output.values} />
          </Descriptions.Item>
          {step.mappingTemplate ?
            <Descriptions.Item span={3} label="mapping template">
              <div>
                {String(step.mappingTemplate).split('\n').map((line) => (
                  <div key={hashStr(line)}>{line}</div>
                ))}
              </div>
            </Descriptions.Item>
            : null
          }
        </Descriptions>
      );
    } else if (step.type === 'enrichment-pipeline' || step.type === 'call-prompt-template') {
      const title = step.type === 'enrichment-pipeline' ? 'Enrichment Pipeline' : 'Call Prompt Template';
      return (
        <Descriptions className="trace-step" title={title} column={{ md: 1, lg: 3 }} layout="vertical">
          <Descriptions.Item span={3} label="input">
            <ReactJson collapsed src={step.args} />
          </Descriptions.Item>
          <Descriptions.Item span={3} label="output">
            <div>
              {step.messages.map((message, i) => (
                <div key={hashStr(message.content)}>
                  <Typography.Paragraph className={i === 0 ? 'first' : ''}>
                    {message.content}
                  </Typography.Paragraph>
                  <Typography.Text type="secondary">
                    role: {message.role}
                  </Typography.Text>
                </div>
              ))}
            </div>
          </Descriptions.Item>
        </Descriptions>
      );
    } else if (step.type === 'call-model') {
      return (
        <Descriptions className="trace-step" title="Call GPT Model" column={{ md: 1, lg: 3 }} layout="vertical">
          <Descriptions.Item span={3} label="input">
            <div>
              {step.messages.map((message, i) => (
                <div key={hashStr(message.content)}>
                  <Typography.Paragraph className={i === 0 ? 'first' : ''}>
                    {message.content}
                  </Typography.Paragraph>
                  <Typography.Text type="secondary">
                    role: {message.role}
                  </Typography.Text>
                </div>
              ))}
            </div>
          </Descriptions.Item>
          <Descriptions.Item span={3} label="output">
            <div>
              {step.response.choices.map((choice, i) => (
                <div key={hashStr(choice.message.content)}>
                  <Typography.Paragraph className={i === 0 ? 'first' : ''}>
                    {choice.message.content}
                  </Typography.Paragraph>
                  <Typography.Text type="secondary">
                    finish reason: {choice.finish_reason}
                  </Typography.Text>
                </div>
              ))}
            </div>
          </Descriptions.Item>
          <Descriptions.Item label="model" span={1}>
            {step.model}
          </Descriptions.Item>
          <Descriptions.Item label="params" span={2}>
            <ReactJson collapsed src={step.modelParams} />
          </Descriptions.Item>
        </Descriptions>
      );
    } else if (step.type === 'call-custom-model') {
      return (
        <Descriptions className="trace-step" title="Call Custom Model" column={{ md: 1, lg: 3 }} layout="vertical">
          <Descriptions.Item span={3} label="input">
            <ReactJson collapsed src={step.args} />
          </Descriptions.Item>
          <Descriptions.Item span={3} label="output">
            <ReactJson collapsed src={step.response} />
          </Descriptions.Item>
          <Descriptions.Item label="model" span={1}>
            {step.model}
          </Descriptions.Item>
          <Descriptions.Item label="params" span={2}>
            <ReactJson collapsed src={step.modelParams} />
          </Descriptions.Item>
          {step.url ?
            <Descriptions.Item label="url" span={3}>
              {step.url}
            </Descriptions.Item>
            : null
          }
          {step.batchEndpoint ?
            <Descriptions.Item label="batch endpoint" span={3}>
              {step.batchEndpoint}
            </Descriptions.Item>
            : null
          }
        </Descriptions>
      );
    }
    return (
      <ReactJson src={step} />
    );
  }

  function Status({ step }) {
    if (!step) {
      return (
        <div></div>
      );
    }
    return (
      <Descriptions className="trace-status" title="Status" column={1} layout="vertical">
        <Descriptions.Item label="start">
          {dayjs(step.startTime).format(TIME_FORMAT)}
        </Descriptions.Item>
        <Descriptions.Item label="end">
          {dayjs(step.endTime).format(TIME_FORMAT)}
        </Descriptions.Item>
        {step.elapsedMillis ?
          <Descriptions.Item label="latency">
            {step.elapsedMillis} ms
          </Descriptions.Item>
          : null
        }
        <Descriptions.Item label="status">
          {step.success || step.valid || !step.errors ?
            <div className="success">
              <CheckCircleFilled />
              Success
            </div>
            :
            <div>
              <CloseCircleFilled />
              Error
            </div>
          }
        </Descriptions.Item>
        {step.errors?.length ?
          <Descriptions.Item label="errors">
            {step.errors.map((err) => (
              <Typography.Paragraph>
                {err.message}
              </Typography.Paragraph>
            ))}
          </Descriptions.Item>
          : null
        }
        {step.response?.usage ?
          <>
            <Descriptions.Item label="prompt tokens">
              {step.response.usage.prompt_tokens}
            </Descriptions.Item>
            <Descriptions.Item label="completion tokens">
              {step.response.usage.completion_tokens}
            </Descriptions.Item>
            <Descriptions.Item label="total tokens">
              {step.response.usage.total_tokens}
            </Descriptions.Item>
          </>
          : null
        }
      </Descriptions>
    );
  }

  if (!loaded) {
    return (
      <div style={{ marginTop: 20 }}>Loading...</div>
    );
  }
  return (
    <div style={{ marginTop: 20 }}>
      <Row gutter={16}>
        <Col span={8}>
          <Descriptions className="trace" title="Trace" column={1} layout="vertical">
            <Descriptions.Item span={1}>
              <Tree
                showLine
                switcherIcon={<DownOutlined />}
                onSelect={onSelect}
                treeData={treeData}
                style={{ padding: '10px 15px' }}
                defaultExpandAll={true}
              />
            </Descriptions.Item>
          </Descriptions>
        </Col>
        <Col span={12}>
          <Step step={step} />
        </Col>
        <Col span={4}>
          <Status step={step} />
        </Col>
      </Row>
    </div>
  );
}
